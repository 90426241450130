import { Providers } from '@microsoft/mgt-element';
import React, { useState, useEffect } from 'react';
import { Tooltip, Rectangle, useMap } from 'react-leaflet'

import CustomMarker from './UserMarker';
import UserStatusMarker from './UserStatusMarker';

const deskNameColor = {
  "free": "rgb(240, 248, 255)",
  // "reserved": "rgb(30, 30, 30)",
  "reserved": "rgb(240, 248, 255)",
  "myseat": "rgb(30, 30, 30)",
  // "myseat": "rgb(240, 248, 255)",
  // "equipment": "rgb(240, 248, 255)",
  "": "rgb(30, 30, 30)"
}

const locationColor = {
  "office": "rgb(221,235,246)",
  "remote": "rgb(252,229,214)",
  "off": "rgb(235,235,235)",
  "free": "rgb(0, 108, 201, 0.8)",
  "shareroom": "rgb(255, 255, 255, 0)",
  "equipment": "rgb(255, 255, 255, 0)",
  "reserved": "rgb(0, 108, 201, 0.8)",
  "myseat": "rgb(252,229,214)"
}

const locationBorderColor = {
  "myseat": "rgb(255, 99, 71)",
  "": "white"
}

const transparentImage = './images/transparent.png';
// const transparentImage = './images/icon_business_man01.png';

const defaultTooltipfontSize = 1.0;

// 席の利用者をマッピングする
//<FixedDeskMarker
// zoomlevel={defaultZoomLevel}
// icon={DefaultIcon}
// iconSize={defaultIconSize}
// hotDesking={reserveMode}
// selectDate={selectedDate}
// selectedLayer={selectedLayer}
// workSpaceURL={FLOOR_NAME.HQF06.workSpaceURL}
// usersURL={FLOOR_NAME.HQF06.usersURL}
// user={userInfo}
///>
export default function FixedDeskMaker(props){

  const [fixedDeskLocationData, setFixedDeskLocationData] = useState(null);
  const [zoomFontSize, setZoomFontSize] = useState(null);
  const [zoomFontWeight, setZoomFontWeight] = useState(null);
  const [usersList, setUsersList] = useState(null);
  const [workSpaceList, setWorkSpaceList] = useState(null);
  const [reservedAction, setReservedAction] = useState(false);

  const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

  // TODO: Tooltipのスタイルを状態によって変化
  // ・ズームレベルによって、文字フォントサイズを変更
  const CustomTooltip = ({ deskid, deskshare, username, deskname, workstat, desktype }) => {
    const map = useMap();

    const changeFontSize = ({zoomlevel}) => {
      
      let fontSize; 
      // if (zoomlevel === -1){
      if (zoomlevel <= -1){
        return (null);
      }
      else if (zoomlevel >= 0 && zoomlevel < 1){
        fontSize = defaultTooltipfontSize + (zoomlevel*0.15);
        return(fontSize + 'em');
      }
      else if (zoomlevel > -1 && zoomlevel < 0 ){
        fontSize = defaultTooltipfontSize + (zoomlevel*0.6);
        return(fontSize + 'em');
      }
      else{
        return ((zoomlevel * 1.2) + 'em');
      }

    }

    useEffect(() => {
      const updateFontSize = () => {
        const currentZoom = map.getZoom();
        const fontSize = changeFontSize({zoomlevel:currentZoom});
        const fontWeight =  currentZoom < 2 ? 'bold' : '';

        setZoomFontSize(fontSize);
        setZoomFontWeight(fontWeight);
      };

      updateFontSize();
      map.on('zoom', updateFontSize);

      return () => {
        map.off('zoom', updateFontSize);
      }

    },[map]);

    if (!zoomFontSize || username === 'free'){
      return;
    }

    const deskName = () => {
      if (desktype !== 'Z' && deskshare === 'hot'){
        return deskid;
      }else{
        if (deskname){
          const desknameSep = deskname.match(/.{1,6}/g);
          if (desknameSep){
            const newtext = desknameSep.join('\n');
            return newtext;
          }
        }else{
          let tooltipName = username.length > 10 ? username.slice(0, 8) + '...' : username;
          return tooltipName;
        }
      }
    }

    return (
      <>
        <Tooltip className='tooltip_username_shadow_off' direction="center" offset={[0, 0]} permanent={true} opacity={1}>
          <div style={{ fontSize: zoomFontSize, color: deskNameColor[workstat], fontWeight: zoomFontWeight, whiteSpace: "pre"}}>
            {deskName()}
          </div>
        </Tooltip>
      </>
    );
  };

  // -----------
  const today = () => {
    if (props.hotDesking){
      if (props.selectDate){
        return props.selectDate;
      }else{
        return new Date();
      }
    }else{
      return new Date();
    }
  }

  const zoomIconSize = ({zoomlevel, iconSize}) => {
    if (zoomlevel >= 0 && zoomlevel < 1){
      return([iconSize[0]*(zoomlevel+1), iconSize[1]*(zoomlevel+1)]);
    }
    else if (zoomlevel > -1 && zoomlevel < 0 ){
      return([iconSize[0]+(iconSize[0]*zoomlevel*0.5555), iconSize[1]+(iconSize[1]*zoomlevel*0.5555)]);
    }
    else{
      return([iconSize[0] * zoomlevel * 2, iconSize[1] * zoomlevel * 2]);
    }
  }

  const initIconSize = props.zoomlevel > -1 ? zoomIconSize({zoomlevel: props.zoomlevel, iconSize: props.iconSize}) : [-1, -1];
  const [iconSize, setIconSize] = useState(initIconSize);

  // Create a custom icon with the specified size
  const map = useMap();

  useEffect(() => {

    const updateIconSize = () => {
      const currentZoom = map.getZoom();
      const updatedSize = zoomIconSize({zoomlevel:currentZoom, iconSize:props.iconSize});
      setIconSize(updatedSize);
    };

    map.on('zoom', updateIconSize);

    return () => {
      map.off('zoom', updateIconSize);
    };

  }, [map]);

  // ---------------------------------
  // 固定席・ミーティングスペース描画
  useEffect(() => {

    const fetchFixedDeskData = async () => {
      try {

        const token = await Providers.globalProvider.getAccessToken();

        const usersResponse = await fetch(props.usersURL, {
          headers: {
            'Authorization': AUTH_TOKEN
          }
        });

        if (usersResponse.ok) {
          const users = await usersResponse.json();
          setUsersList(users.value);

          const meResponse = await fetch(`https://graph.microsoft.com/v1.0/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (meResponse.ok) {
            const meData = await meResponse.json();
            
            const workSpaceResponse = await fetch(props.workSpaceURL, {
              headers: {
                'Authorization': AUTH_TOKEN
              }
            });
            

            if (workSpaceResponse.ok) {
              const workSpace = await workSpaceResponse.json();
              setWorkSpaceList(workSpace.value);
            
              const workSpacePromises = workSpace.value.map(async (wslist) => {
                if (wslist.desktype !== 'Z' && wslist.userid !== 'free'){ 

                  if (wslist.deskshare !== 'hot'){

                    const myDesk = () => {
                      if (wslist.userid === meData.mail){
                        return 'myseat';
                      }else{
                        if (props.hotDesking && wslist.deskshare !== 'hot'){
                          return 'off';
                        }else{
                          return wslist.workstat;
                        }
                      }
                    }

                    if (wslist.username){
                      return {
                        deskId: wslist.deskid,
                        deskType: wslist.desktype,
                        position: wslist.position,
                        workStat: myDesk(),
                        userId: wslist.userid,
                        userName: wslist.username,
                        deskName: wslist.diplayname,
                        deskShare: wslist.deskshare,
                        worktype: wslist.worktype,

                        photo: transparentImage,
                        work: "clockin",
                        workspace: { deskid: wslist.deskid, desktype: wslist.desktype, position: wslist.position, workstat: "office" },
                        searchDate: today(),
                        hotDesk : ''

                      }

                    }else{
                      return {
                        deskId: wslist.deskid,
                        deskType: wslist.desktype,
                        position: wslist.position,
                        workStat: myDesk(),
                        userId: wslist.userid,
                        userName: wslist.username,
                        deskName: wslist.username,
                        //deskName: userData.displayName,
                        deskShare: wslist.deskshare,
                        worktype: wslist.worktype,

                        photo: '',
                        work: "off",
                        workspace: { deskid: wslist.deskid, desktype: wslist.desktype, position: wslist.position, workstat: "office" },
                        searchDate: today(),
                        hotDesk : ''
                      }
                    }
                  }
                }
                
                if (wslist.desktype === 'Z'){
                  // const usersData = await users.value.filter(us => us.comment && ( us.worktype === 'shareroom' || us.worktype === 'equipment') && us.userPrincipalName === wslist.userPrincipalName);
                  const usersData = await users.value.filter(us => us.comment && ( us.usertype === 'shareroom' || us.usertype === 'equipment') && us.userPrincipalName === wslist.userPrincipalName);
                  const userComment = usersData.length > 0 ? usersData[0].comment : '';
                  
                  return {
                    deskId: wslist.deskid,
                    deskType: wslist.desktype,
                    position: wslist.position,
                    workStat: wslist.workstat,
                    userId: wslist.userid,
                    userName: wslist.username,
                    deskName: wslist.diplayname,
                    deskShare: wslist.deskshare,
                    worktype: wslist.worktype,
                    comment: userComment,
                    photo: '',
                    work: "off",
                    workspace: { deskid: wslist.deskid, desktype: wslist.desktype, position: wslist.position, workstat: "office" },
                    searchDate: today(),
                    hotDesk : ''
                  }
                }

              });

              // マーカー情報をセット
              const workSpaceResults = await Promise.all(
                workSpacePromises.map((promise) => promise || { userId: '', presence: {}, displayName: '', work: '', workspace: {deskid: '', desktype: '', position: '', workstat: ''}})
              );

              setFixedDeskLocationData(workSpaceResults);

            }
          }
        }
      } catch (error) {
        console.error('Error fetching warkspace data', error);
      }
    };
    fetchFixedDeskData()

  // }, [props.hotDesking]);
  }, []);
  

  if ( fixedDeskLocationData === null ){
    return
  }

  const locationBounds = ({desktype, workstat, bounds}) => {
    if (desktype === 'Z'){
      if (workstat === 'equipment'){
        const centerPosition = [(bounds[1][0] - bounds[0][0]) /2 + bounds[0][0]-12, ((bounds[1][1] - bounds[0][1]) /2 + bounds[0][1]-5)];
        const toolTipsPosition = [[centerPosition[0]-10, centerPosition[1]-10],[centerPosition[0], centerPosition[1]+10]];
        
        return toolTipsPosition;
      }else{
        const centerPosition = [(bounds[1][0] - bounds[0][0]) /2 + bounds[0][0], ((bounds[1][1] - bounds[0][1]) /2 + bounds[0][1])];
        const toolTipsPosition = [[centerPosition[0]-10, centerPosition[1]-10],[centerPosition[0]+10, centerPosition[1]+10]];
        
        return toolTipsPosition;
      }
    }
    return bounds;
  }

  const WorkspaceLocation = ({wsData, index, iconSize, props}) => {

    if (wsData === undefined){
      return null;
    }

    return (
      <>
        <Rectangle
          key={index}
          bounds={locationBounds({desktype:wsData.deskType, workstat:wsData.workStat, bounds:wsData.position})}
          pathOptions={
            {
              color: locationBorderColor[wsData.workStat],
              fillColor: locationColor[wsData.workStat],
              fillOpacity: '1',
              weight: '1',
              radius: '30'
            }
          }
        >
        <CustomTooltip
          deskid={wsData.deskId}
          deskshare={wsData.deskShare}
          username={wsData.userName}
          deskname={wsData.deskName}
          workstat={wsData.workStat}
          desktype={wsData.deskType}
        />
        </Rectangle>
        
        <CustomMarker
          key={wsData.deskId}
          userData={wsData}
          index={index}
          iconSize={iconSize}
          hotDesking={props.hotDesking}
          selectDate={props.selectDate}
          zoomlevel={props.zoomlevel}
          deskshare={wsData.deskShare}
          worktype={wsData.worktype}
          comment={wsData.comment}
          setReservedAction={setReservedAction}
          parameters={props.parameters}
        />
      </>
    )

  }

  return (
    <>
      {fixedDeskLocationData !== undefined && (
        fixedDeskLocationData.map((wsData, index) => (
          <WorkspaceLocation key={index} wsData={wsData} index={index} iconSize={iconSize} props={props}/>          
        ))
      )}
      {props.hotDesking ? (
        <UserStatusMarker
          iconSize={iconSize}
          selectDate={props.selectDate}
          hotDesking={true}
          usersList={usersList}
          workSpaceList={workSpaceList.filter(ws => ws.userid && ( ws.worktype === 'shareroom' || ws.worktype === 'equipment'))}
          presenceURL={props.presenceURL}
          hotdeskURL={props.hotdeskURL}
          user={props.user}
        />
      ):(
        <UserStatusMarker
          iconSize={iconSize}
          selectDate={props.selectDate}
          hotDesking={false}
          usersList={usersList}
          workSpaceList={workSpaceList}
          presenceURL={props.presenceURL}
          hotdeskURL={props.hotdeskURL}
          user={props.user}
        />
      )}
    </>
  );
}

