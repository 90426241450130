import React, { useState, useRef } from 'react';
import { CRS, LatLng, LatLngBounds} from 'leaflet';
import { MapContainer, ImageOverlay, useMap, LayersControl, LayerGroup, TileLayer } from 'react-leaflet';
import Modal from 'react-modal';

//npm install @material/textfield
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//npm install moment-timezone
//npm install @mui/x-date-pickers
//npm install date-fns
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { IconButton, Box, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EditNoteIcon from '@mui/icons-material/EditNote'; //管理画面用に追加

import Leaflet from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import 'leaflet/dist/leaflet.css';
import './styles/Map.css';

import FixedDeskMarker from './FixedDeskMarker';
import HotDeskMarker from './HotDeskMarker';
import ScheduleListModal from './ScheduleListModal';
import BookRegister from './BookRegister'; //管理画面用に追加

const  parameters = {
  'accountDomian' : 'mtf.co.jp',
};

const MESSAGE = {
  FLOOR: "FLOOR",
  // DATE_PICKER: "Date Picker",
  DATE_PICKER: "Select Date",
}

const DATE_FORMAT = "yyyy/MM/dd";

const FLOOR_NAME = {
  //HQF06: { floorId: "601", displayName: "本社６F", workSpaceURL: "./api/workspaces2", usersURL: "./api/users" },
  //HQF0X: { floorId: "X01", displayName: "仮想オフィス", workSpaceURL: "./api/workspaces-x01", usersURL: "./api/users" },
  HQF06: {
    floorId: "601",
    displayName: "本社６F",
    workSpaceURL: "./api/workspaces2",
    usersURL: "./api/users",
    presenceURL: "./api/presence",
    hotdeskURL: "./api/hotdesk"
  },
  HQF0X: {
    floorId: "X01",
    displayName: "仮想オフィス",
    workSpaceURL: "./api/workspaces-x01",
    usersURL: "./api/users",
    presenceURL: "./api/presence",
    hotdeskURL: "./api/hotdesk"
  },
}

const mapImage = {
  "HQF06" : { "width" : 2164, "hight" : 1258, "bgImage" : "./images/OfficeLayout2409H6F_2164_1258.png" },
  "HQF0X" : { "width" : 2164, "hight" : 1258, "bgImage" : "./images/OfficeLayout2409X01_2164_1258.png" },
};

const mapBounds = {
  "HQF06" : new LatLngBounds([mapImage.HQF06.hight,0],[0,mapImage.HQF06.width]),
  "HQF0X" : new LatLngBounds([mapImage.HQF0X.hight,0],[0,mapImage.HQF0X.width]),
};  

const defaultZoomLevel = -0.5;
// const defaultHome = [mapImage.width/2, mapImage.hight/2];
const defaultHome = [700, 615];
const defaultIconSize = [30, 30];
const DefaultIcon = Leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [15, 14],
});

const selectListOpacity = 1.0;
const actionButtonOpacity = 0.8;

const hotdeskTimeReserve = false; //フリー席予約に時間を指定する場合はtrue


export default function Office({ user }) {
  
  const [selectedLayer, setSelectedLayer] = useState(FLOOR_NAME.HQF0X.floorId);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAmPm, setSelectedAmPm] = useState('allday');
  const [reserveMode, setReserveMode] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [reservedCancel, setReservedCancel] = useState(null);
  const [today, setToday] = useState(new Date());

  const handleChange = (e) => {
    setSelectedLayer(e.target.value);

    if (e.target.value === FLOOR_NAME.HQF06.floorId){
      setSelectedDate(new Date());
      setReserveMode(false);
    }
  };

  const dateChange = (e) => {
    setSelectedDate(e);

    // if (!e || e.toDateString() === (new Date()).toDateString()){
    if (!e){
      setReserveMode(false);
    }else{
      setReserveMode(true);
      setSelectedAmPm('allday');
    }
    
  };

  const dateAmPmChange = (event) => {
    setSelectedAmPm(event.target.value);
  };

  const CustomDatePicker = () => {

    const handleDateChange = (e) => {
      if (openCalendar){
        setOpenCalendar(false);
        setSelectedDate(null);
        setReserveMode(false);
        setToday(new Date())
        setSelectedAmPm('allday');
      }else{
        setOpenCalendar(true);
      }
    }
  
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 9,
          left: 170,
          zIndex: 1000,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column', // ボタンを縦に並べるために追加
            alignItems: 'center',
            opacity: actionButtonOpacity, // Paperの透明度を設定
          }}
        >
          <IconButton onClick={handleDateChange}>
            <CalendarTodayIcon fontSize="small" />            
          </IconButton>
        </Paper>
      </Box>
    )
  }

  return (
    <div>
      <div className="custom-location-floor">
        <div>
          <FormControl sx={{ m: 1, mr:13, minWidth: 150 }} size="small">
            <InputLabel id="select-autowidth-label">{MESSAGE.FLOOR}</InputLabel>
            <Select
              labelId="select-autowidth-label"
              id="select-autowidth" 
              value={selectedLayer}
              onChange={handleChange}
              autoWidth
              label={MESSAGE.FLOOR}
            >
              <MenuItem key="1" value={FLOOR_NAME.HQF0X.floorId} >{FLOOR_NAME.HQF0X.displayName}</MenuItem>
              <MenuItem key="2" value={FLOOR_NAME.HQF06.floorId} >{FLOOR_NAME.HQF06.displayName}</MenuItem>
            </Select>
          </FormControl>
          <CustomDatePicker />
          <ReservedListButtons setReservedCancel={setReservedCancel}/>
        </div>
        {openCalendar && (
          <div className="date">
            {/* <LocalizationProvider
              dateAdapter={AdapterDateFns}
            >
              <Box sx={{ m: 1, width: 195 }}>
                <DatePicker
                  label={MESSAGE.DATE_PICKER}
                  format={DATE_FORMAT}
                  value={selectedDate}
                  onChange={selectedDate => { dateChange(selectedDate || today) }}
                  minDate={today}
                  slotProps={{textField: { size: 'small' }}} 
                />
              </Box>
            </LocalizationProvider> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ display: 'flex', m: 1, width: 245 }}>
                <DatePicker
                  label={MESSAGE.DATE_PICKER}
                  format={DATE_FORMAT}
                  value={selectedDate}
                  onChange={selectedDate => { dateChange(selectedDate || today) }}
                  minDate={today}
                  slotProps={{textField: { size: 'small' }}} 
                />
                {selectedDate && hotdeskTimeReserve && 
                <Select
                  value={selectedAmPm}
                  onChange={selectedDate => { dateAmPmChange(selectedDate || today) }}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="allday">終日</MenuItem>
                  <MenuItem value="am">AM</MenuItem>
                  <MenuItem value="pm">PM</MenuItem>
                </Select>
                }
              </Box>
            </LocalizationProvider>
          </div>
        )}
      </div>
        <div>
          <MapContainer
              className="map"
              center={defaultHome}
              zoom={defaultZoomLevel}
              minZoom={-0.9}
              maxZoom={1}
              doubleClickZoom={false}
              scrollWheelZoom={false}
              // doubleClickZoom={true}
              // scrollWheelZoom={true}
              crs={CRS.Simple}
              attributionControl={false}
              zoomControl={false}
              zoomDelta={0.4}
              zoomSnap={0}
              style={{width: "100%", height: "100svh"}}
              tap={false}
              closePopupOnClick={true}
              // whenReady={handleDragEnd} // ドラッグ終了時に呼び出されるコールバック
              // dragging={true} // 地図のドラッグを有効にします
            >
            <TileLayer
              attribution='&copy; <a href="https://mtf.co.jp/">written by MTF</a>'
              url=""
            />
            <DefaultPosition mapHight={defaultHome[0]} mapWidth={defaultHome[1]} />

            <LayersControl position="bottomleft">
              <LayersControl.BaseLayer checked={selectedLayer === FLOOR_NAME.HQF06.floorId} name={FLOOR_NAME.HQF06.displayName}>
                <LayerGroup>
                  <ImageOverlay
                    url={mapImage.HQF06.bgImage}
                    bounds={mapBounds.HQF06}
                    opacity={selectListOpacity}
                    eventHandlers={{
                      add: (e) => {
                        setSelectedLayer(FLOOR_NAME.HQF06.floorId);
                      }
                    }}
                  />
                  <FixedDeskMarker
                    zoomlevel={defaultZoomLevel}
                    iconSize={defaultIconSize}
                    hotDesking={reserveMode}
                    selectDate={selectedDate}
                    selectedAmPm={selectedAmPm}
                    selectedLayer={selectedLayer}
                    workSpaceURL={FLOOR_NAME.HQF06.workSpaceURL}
                    usersURL={FLOOR_NAME.HQF06.usersURL}
                    presenceURL={FLOOR_NAME.HQF06.presenceURL}
                    hotdeskURL={FLOOR_NAME.HQF06.hotdeskURL}
                    user={user}
                    parameters={parameters}
                  />
                  <HotDeskMarker
                    zoomlevel={defaultZoomLevel}
                    iconSize={defaultIconSize}
                    hotDesking={reserveMode}
                    selectDate={selectedDate}
                    selectedAmPm={selectedAmPm}
                    selectedLayer={selectedLayer}
                    reservedCancel={reservedCancel}
                    workSpaceURL={FLOOR_NAME.HQF06.workSpaceURL}
                    usersURL={FLOOR_NAME.HQF06.usersURL}
                    presenceURL={FLOOR_NAME.HQF06.presenceURL}
                    hotdeskURL={FLOOR_NAME.HQF06.hotdeskURL}
                    user={user}
                    parameters={parameters}
                  />
                </LayerGroup>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer checked={selectedLayer === FLOOR_NAME.HQF0X.floorId} name={FLOOR_NAME.HQF0X.displayName}>
                <LayerGroup>
                  <ImageOverlay
                    url={mapImage.HQF0X.bgImage}
                    bounds={mapBounds.HQF0X}
                    opacity={selectListOpacity}
                    eventHandlers={{
                      add: (e) => {
                        setSelectedLayer(FLOOR_NAME.HQF0X.floorId);
                      }
                    }}
                  />
                  <FixedDeskMarker
                    zoomlevel={defaultZoomLevel}
                    iconSize={defaultIconSize}
                    hotDesking={reserveMode}
                    selectDate={selectedDate}
                    selectedAmPm={selectedAmPm}
                    selectedLayer={selectedLayer}
                    workSpaceURL={FLOOR_NAME.HQF0X.workSpaceURL}
                    usersURL={FLOOR_NAME.HQF0X.usersURL}
                    presenceURL={FLOOR_NAME.HQF0X.presenceURL}
                    hotdeskURL={FLOOR_NAME.HQF0X.hotdeskURL}
                    user={user}
                    parameters={parameters}
                  />
                  <HotDeskMarker
                    zoomlevel={defaultZoomLevel}
                    iconSize={defaultIconSize}
                    hotDesking={reserveMode}
                    selectDate={selectedDate}
                    selectedAmPm={selectedAmPm}
                    selectedLayer={selectedLayer}
                    reservedCancel={reservedCancel}
                    workSpaceURL={FLOOR_NAME.HQF0X.workSpaceURL}
                    usersURL={FLOOR_NAME.HQF0X.usersURL}
                    presenceURL={FLOOR_NAME.HQF0X.presenceURL}
                    hotdeskURL={FLOOR_NAME.HQF0X.hotdeskURL}
                    user={user}
                    parameters={parameters}
                  />
                </LayerGroup>
              </LayersControl.BaseLayer>
            </LayersControl>

            <ZoomControlButtons />
            
            {/* 追加 */}
            <AdminButtons user={user} />
            {/* 追加ここまで */}

            {/* TEST */}
            {/* <PositionMarker position={defaultHome}/> */}
            {/* TEST */}

          </MapContainer>
          
        </div>
    </div>
  )
};

function DefaultPosition(props) {
  const map = useMap();
  const position = new LatLng(props.mapHight, props.mapWidth);

  const handleHome = (e) => {
    map.panTo(position);
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 18,
        bottom: 120,
        zIndex: 1000,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column', // ボタンを縦に並べるために追加
          alignItems: 'center',
          opacity: actionButtonOpacity, // Paperの透明度を設定
        }}
      >
        <IconButton onClick={handleHome}>
          <HomeIcon fontSize="small" />
        </IconButton>
      </Paper>
    </Box>
  )
}

// 管理画面追加

const AdminButtons = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isAdminUser = user && (user.mail === 'chiba@mtf.co.jp' || user.mail === 'hiroyuki.h@mtf.co.jp');

    if (!isAdminUser) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 18,
        bottom: 180,
        zIndex: 1000,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column', // ボタンを縦に並べるために追加
          alignItems: 'center',
          opacity: actionButtonOpacity, // Paperの透明度を設定
        }}
      >
        <IconButton onClick={handleOpenModal}>
          <EditNoteIcon fontSize="small" />
        </IconButton>
      </Paper>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="BookRegistar"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <BookRegister onClose={handleCloseModal}/>
      </Modal>

    </Box>
  );
};

// ここまで

const ZoomControlButtons = () => {
  const map = useMap();

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 18,
        bottom: 30,
        zIndex: 1000,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column', // ボタンを縦に並べるために追加
          alignItems: 'center',
          opacity: actionButtonOpacity, // Paperの透明度を設定
        }}
      >
        <IconButton onClick={handleZoomIn}>
          <AddIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={handleZoomOut}>
          <RemoveIcon fontSize="small" />
        </IconButton>
      </Paper>
    </Box>
  );
};

const ReservedListButtons = ({setReservedCancel}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setReservedCancel(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setReservedCancel(null);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 9,
        left: 215,
        zIndex: 1000,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column', // ボタンを縦に並べるために追加
          alignItems: 'center',
          opacity: actionButtonOpacity, // Paperの透明度を設定
        }}
      >
        <IconButton onClick={handleOpenModal}>
          <ListAltIcon fontSize="small" />
        </IconButton>
      </Paper>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="reserved-list"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ScheduleListModal onClose={handleCloseModal} setReservedCancel={setReservedCancel}/>
      </Modal>
    </Box>
  );
};

// test test test

// function PositionMarker(props){
//   // https://zenn.dev/micronn/articles/776c702089f16e
//   const defaultPosition = [652, 521];

//   const markerDefaultPosition = () => {
//     if (props.position){
//       return props.position;
//       // return defaultPosition;
//     }else{
//       return defaultPosition;
//     }
//   };

//   // const markerDefaultPosition = [81, 753];
//   const [markerPosition, setMarkerPosition] = useState(Leaflet.latLng(markerDefaultPosition()));
//   const markerRef = useRef(null);
//   const eventHandlers = {
//     dragstart: () => {
//       const marker = markerRef.current;
//       marker.setOpacity(0.6);
//     },
//     dragend: () => {
//       const marker = markerRef.current;
//       marker.setOpacity(1);
//       setMarkerPosition(marker.getLatLng());
//     }
//   };

//   return (
//     <Marker
//       ref={markerRef}
//       position={markerPosition}
//       draggable={true}
//       eventHandlers={eventHandlers}
//       icon={DefaultIcon} 
//     >
//       <Popup direction="center" offset={[0, -15]}>
//       {`${markerPosition.lat}, ${markerPosition.lng}`}
//       </Popup>
//     </Marker>
//   )
// }

